import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function Share({ size, color }: ComponentProps) {
  return (
    <BaseSvgStyle
      size={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <BaseIconPathStyle
        d="M17.25 8.25H18.75C19.1478 8.25 19.5294 8.40804 19.8107 8.68934C20.092 8.97064 20.25 9.35218 20.25 9.75V21.75C20.25 22.1478 20.092 22.5294 19.8107 22.8107C19.5294 23.092 19.1478 23.25 18.75 23.25H5.25C4.85218 23.25 4.47064 23.092 4.18934 22.8107C3.90804 22.5294 3.75 22.1478 3.75 21.75V9.75C3.75 9.35218 3.90804 8.97064 4.18934 8.68934C4.47064 8.40804 4.85218 8.25 5.25 8.25H6.75"
        stroke={color}
        strokeRound
      />
      <BaseIconPathStyle d="M12 0.75V11.25" stroke={color} strokeRound />
      <BaseIconPathStyle
        d="M8.25 4.5L12 0.75L15.75 4.5"
        stroke={color}
        strokeRound
      />
    </BaseSvgStyle>
  );
}

export default Share;
